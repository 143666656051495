<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-dialog v-model="display" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-badge :value="numFilters" bordered overlap color="deep-purple" :content="numFilters">
          <v-btn small color="primary" v-on="on"><v-icon small left>mdi-filter</v-icon>Filter</v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Commit Filters</span>
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <project-combobox v-model="local_project" label="Projects" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field v-model="local_author" label="Author" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field v-model="local_repository" label="Repository" />
            </v-list-item-content>
          </v-list-item>
          <!-- Add or remove filters based on your commit model -->
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="applyFilters()"> Apply Filters </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  import { sum } from "lodash"
  import { mapFields } from "vuex-map-fields"

  import ProjectCombobox from "@/project/ProjectCombobox.vue"

  export default {
    name: "CommitTableFilterDialog",

    components: {
      ProjectCombobox,
      // Remove or include additional components based on your filters
    },

    data() {
      return {
        display: false,
        local_project: [],
        local_author: '',
        local_repository: '',
        // Initialize local states for your filters
      }
    },

    computed: {
      ...mapFields("commit", [
        "table.options.filters.project",
        "table.options.filters.author",
        "table.options.filters.repository",
        // Map additional fields as needed
      ]),
      numFilters: function () {
        return sum([
          this.local_project.length,
          this.local_author.length > 0 ? 1 : 0,
          this.local_repository.length > 0 ? 1 : 0,
          // Account for additional filters
        ])
      },
    },

    methods: {
      applyFilters() {
        // Set the filter values
        this.project = this.local_project
        this.author = this.local_author
        this.repository = this.local_repository
        // Apply additional filters as needed

        // Close the dialog
        this.display = false
      },
    },
  }
  </script>
