<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid>
    <new-edit-sheet />
    <v-row no-gutters class="mb-1">
    <v-col>
        <div class="headline">
        <v-icon large>mdi-git</v-icon>
        Commits
        </div>
    </v-col>
    <v-col class="text-right">
        <table-filter-dialog />
    </v-col>
    </v-row>
    <v-row align="center" justify="space-between" no-gutters>
    </v-row>
    <v-row no-gutters>
    <v-col>
        <v-card elevation="0">
        <v-card-title>
            <v-text-field
            v-model="q"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            />
        </v-card-title>
        <v-row>
          <v-expansion-panels popout>
            <v-col cols="12" v-for="item in items" :key="item.id">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="pl-6 pr-6" no-gutters align="center" justify="space-between">
                    <!-- Commit message and icon on the left -->
                    <v-col cols="6" class="d-flex align-center">
                      <div class="d-flex align-center">
                        <v-icon left color="success">mdi-git</v-icon>
                        <div class="ml-2">
                          <div class="custom-text-size">
                            {{ truncateText(item.commit_message) }}
                          </div>
                          <div class="mt-2 caption font-weight-light">
                            {{ item.commit_date | formatRelativeDate }} by
                            {{ item.commit_author }}
                          </div>
                          <div class="mt-2 caption font-weight-light">
                            <v-chip small>
                              <v-icon small left color="error">mdi-source-repository</v-icon>
                              <div v-if="item.commit_raw && item.commit_raw.intent">
                                {{ item.commit_raw.intent }}
                              </div>
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="2">
                      <v-list dense>
                            <v-list-item v-for="entity in item.entities" :key="entity.id">
                              <v-list-item-content>
                                <entity-popover :entity="entity" />
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                    </v-col>

                    <!-- Chips, entities, and dialog icon on the right -->
                    <v-col cols="4">
                      <div class="d-flex justify-end align-center">
                        <!-- Chips for date and repository -->
                        <v-chip small class="ma-1">
                          <v-icon small left color="primary">mdi-calendar-clock</v-icon>
                          {{ item.commit_date | formatRelativeDate }}
                        </v-chip>

                        <!-- Icons for entities and commit raw dialog -->
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon color="primary">mdi-tag-multiple</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item v-for="entity in item.entities" :key="entity.id">
                              <v-list-item-content>
                                <entity-popover :entity="entity" />
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <commit-raw-dialog :commit_raw="item.commit_raw" />
                        <v-btn icon @click="showText(item.commit_summary)">
                          <v-icon color="error">mdi-creation</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-card outlined class="ma-2 elevation-2">
                      <v-card-title class="justify-space-between">
                      {{ item.commit_message }}
                      <v-spacer></v-spacer>
                      <a :href="item.commit_url" target="_blank">
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                      </a>
                      </v-card-title>
                      <v-card-text>
                        <div class="d-flex align-center mb-2">
                          <v-chip>
                          <v-icon left color="secondary">{{ item.source === 'GitHub' ? 'mdi-github' : 'mdi-source-branch' }}</v-icon>
                          {{ item.source }}
                          </v-chip>
                          <v-spacer></v-spacer>
                          <v-chip>
                          <v-icon left color="primary">mdi-source-repository</v-icon>
                          <div v-if="item.commit_raw && item.commit_raw.intent">
                            {{ truncateText(item.commit_raw.intent) }}
                          </div>
                          </v-chip>
                        </div>
                        <div>
                          <v-icon small left>mdi-source-repository</v-icon>
                          <strong>Commit SHA:</strong> {{ item.commit_id }}
                        </div>
                        <div>
                            <v-icon small left>mdi-account-check-outline</v-icon>
                            <strong>Author:</strong> {{ item.commit_author }}
                        </div>
                        <div>
                            <v-icon small left color="primary">mdi-calendar-clock</v-icon>
                            <strong>Date:</strong> {{ item.commit_date | formatRelativeDate }}
                        </div>
                        <div>
                            <v-icon small left>mdi-source-branch</v-icon>
                            <strong>Branch:</strong> {{ item.repository_branch }}
                        </div>
                        <div>
                            <v-icon small left>mdi-web</v-icon>
                            <strong>Repository:</strong>
                            {{ item.repository_url }}
                            <a :href="item.repository_url" target="_blank">
                              <v-icon class ="ml-2" color="primary" small left>mdi-open-in-new</v-icon>
                            </a>
                        </div>
                        <div>
                            <v-icon small left>mdi-code-tags</v-icon>
                            <strong>Entities:</strong>
                            <div v-for="entity in item.entities" :key="entity.id" class="mb-1">
                              <entity-popover :entity="entity" />
                            </div>
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <commit-raw-dialog :commit_raw="item.commit_raw" />
                        <v-icon class="mr-2 ml-2" color="purple" @click="showText(item.commit_summary)">
                          mdi-creation
                        </v-icon>
                          <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                              <v-list-item @click="createEditShow(item)">
                              <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item>
                          </v-list>
                          </v-menu>
                      </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </v-expansion-panels>
        </v-row>
         <!-- Dialog for showing text -->
         <v-dialog v-model="isTextViewDialogOpen" max-width="600px">
            <v-card>
              <v-card-title class="text-h5">
                <v-icon left>mdi-auto-fix</v-icon>
                AI Commit Summary
                <v-spacer></v-spacer>
                <v-btn
                icon
                @click="copyToClipboard(currentViewingText)"
                class="ml-4"
              >
                <v-icon left>mdi-content-copy</v-icon>
              </v-btn>
              </v-card-title>
              <v-card-text v-html="currentViewingText"></v-card-text>
              <v-card-actions>
                <v-btn icon @click="onLike">
                  <v-icon>mdi-thumb-up-outline</v-icon>
                </v-btn>
                <v-btn icon @click="onDislike">
                  <v-icon>mdi-thumb-down-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeTextDialog">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100],
            }"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
    </v-col>
    </v-row>
</v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import TableFilterDialog from "@/commit/TableFilterDialog.vue"
import RouterUtils from "@/router/utils"
import NewEditSheet from "@/commit/NewEditSheet.vue"
import EntityPopover from '@/entity/EntityPopover.vue'
import CommitRawDialog from '@/commit/CommitRawDialog.vue'

export default {
name: "CommitTable",
components: {
    TableFilterDialog,
    NewEditSheet,
    EntityPopover,
    CommitRawDialog,
},
data() {
    return {
      headers: [],
      currentViewingText: '',
      isTextViewDialogOpen: false,
    }
  },

  computed: {
    ...mapFields("commit", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
  },

  methods: {
    ...mapActions("commit", ["getAll", "createEditShow", "removeShow", "copyToClipboardSuccess"]),
    closeTextDialog() {
      this.isTextViewDialogOpen = false
      this.currentViewingText = ''
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copyToClipboardSuccess()
      })
    },
    onLike() {
      this.closeTextDialog()
    },
    onDislike() {
      this.closeTextDialog()
    },
    showText(text) {
      this.currentViewingText = text
      this.isTextViewDialogOpen = true
    },
    truncateText(text) {
      if (!text) {
        return ''
      }
      return text.length > 50 ? text.substring(0, 50) + "..." : text
    },
  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.commit),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },
}
</script>

<style scoped>
.custom-text-size {
  font-size: 18px; /* Choose a size between h6 and subtitle-1 */
  font-weight: bold;
}
</style>
